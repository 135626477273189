// Sprinkle in some vanilla js
import './form.js';


// *** HELPERS
// Check for reduced motion setting
const reducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

// Check if touch device
const touchDevice = matchMedia('(hover: none)').matches;
// ***




// *** TIME
// Define time format options
let options = {
    timeZone: 'Europe/Berlin',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
},
formatter = new Intl.DateTimeFormat('de-DE', options); // en-AU for pm/am

// Find time element
const time = document.querySelectorAll('.js-time');
let currentTime;

if (time) {
    // Post initial time
    currentTime = formatter.format(new Date());
    for (let i = 0; i < time.length; ++i) {
        time[i].innerHTML = currentTime;
    }

    // Update time all the time
    setInterval(function () {
        currentTime = formatter.format(new Date());
        for (let i = 0; i < time.length; ++i) {
            setTimeout(function () {
                time[i].innerHTML = currentTime;
            }, 20); // wait a tiny bit to feel in sync with second handle
        }
    }, 1000);
}
// ***




// *** CLOCK
// Show big clock on hover or click
const clockTrigger = document.querySelector('.js-show-clock');
const clock = document.querySelector('.js-clock');
const toggleClockVisibility = () => {
    document.body.classList.toggle("has-clock");
}

if(clockTrigger && clock) {
    if (touchDevice) {
        clockTrigger.addEventListener("click", toggleClockVisibility);
        clock.addEventListener("click", toggleClockVisibility);
    } else {
        clockTrigger.addEventListener("mouseenter", toggleClockVisibility);
        clockTrigger.addEventListener("mouseleave", toggleClockVisibility);
    }
}

// Determine clock hands rotation
let deg = 6;

const hourNode = document.querySelectorAll('.js-clock-hour');
const minuteNode = document.querySelectorAll('.js-clock-min');
const secondNode = document.querySelectorAll('.js-clock-sec');

// keep track of seconds hand rotation
let secDeg = 0;

// Live clock update
if(time) {
    setInterval(()=>{
        // get desired degree for clock hands
        let day = new Date();
        let hours = day.getHours() * 30;
        let minutes = day.getMinutes() * deg;
        let seconds = day.getSeconds() * deg;

        // Calculate seconds hand degree
        let apparentSecDeg = secDeg % 360;
        if ( apparentSecDeg < 0 ) { secDeg += 360; }
        if ( apparentSecDeg < 180 && (seconds > (apparentSecDeg + 180)) ) { secDeg -= 360; }
        if ( apparentSecDeg >= 180 && (seconds <= (apparentSecDeg - 180)) ) { secDeg += 360; }
        secDeg += (seconds - apparentSecDeg);

        // rotate clock hands
        for (let i = 0; i < hourNode.length; ++i) {
            hourNode[i].style.transform = `rotateZ(${(hours) + (minutes / 12)}deg)`;
            minuteNode[i].style.transform = `rotateZ(${minutes}deg)`;
            secondNode[i].style.transform = `rotateZ(${secDeg}deg)`;
        }
    }, 1000);
}
// ***




// *** RANDOM SPLASH IMAGE
// Look for slides
const slides = document.querySelectorAll('.js-slide');

// iterate over all slide image containers
for (let i = 0; i < slides.length; i++) {
    const slidesImages = slides[i].querySelectorAll('.js-slide-image');

    // iterate over all available images
    for (let j = 0; j < slidesImages.length; j++) {
        // get all available images
        const allImages = slidesImages[j].querySelectorAll('img');

        // check if more than one image
        if(allImages.length > 1) {
            // calculate a random index
            const randomIndex = Math.floor(Math.random() * (allImages.length - 0) + 0);

            // check if image is left or right
            const parentClass = allImages[randomIndex].parentElement.className;

            // store index, max index and element
            slidesImages[j].dataset.index = randomIndex;
            slidesImages[j].dataset.maxIndex = allImages.length-1;
            parentClass === 'right' ?
                slidesImages[j].classList.add('right-splash') :
                slidesImages[j].classList.add('left-splash');
        }
    }
}
// ***




// *** SLIDER
// Config / base settings
const slider = document.querySelector(".js-slider");

const sliderTiming = slider.dataset.timing; // defaults to 2s and can be changed in template
const autoSliderTimeOut = 20000;            // slider restarts after 20s pause
let timeOutInterval;                        //
let autoSlider;                             //


// Custom cursor texts
const next = '(next)';
const prev = '(prev)';

// Remove lazy attribute from slide images
window.addEventListener('load', () => {
    const imgs = document.querySelectorAll('.js-slide img');

    for (let i = 0; i < imgs.length; ++i) {
        imgs[i].setAttribute('loading', 'eager');
    }
});

// Custom cursor only for non-touch devices
const elem = document.querySelector(".js-custom-cursor");

if(!touchDevice && elem) {
    window.addEventListener('mousemove', cursorPosition);
}

// Custom cursor over slide
function cursorPosition(e) {

    // Change cursor text
    if(window.innerWidth/2 >= e.clientX) {
        elem.innerText = prev;
        elem.dataset.direction = 'prev';
    } else {
        elem.innerText = next;
        elem.dataset.direction = 'next';
    }

    // Position custom cursor
    elem.style.top = e.clientY + "px";
    elem.style.left = e.clientX + "px";
}

// Add slider interaction
if (slides.length) {

    // check if navigation should be active
    if(slider.dataset.nav == 'true') {

        // Add click event to all imgs
        for (let i = 0; i < slides.length; i++) {
            const currentFigures = slides[i].querySelectorAll('figure');

            for (let j = 0; j < currentFigures.length; j++) {

                // Click event listener stop and navigate slides
                currentFigures[j].addEventListener('click', event => {

                    // Stop automated timer
                    stopAutoSlider();

                    // Reset restarting of auto slider
                    clearTimeout(timeOutInterval);

                    // Restart slider after some timeout
                    timeOutInterval = setTimeout(function (e) {
                        autoSlider = setInterval(startSlideTimer, sliderTiming);
                    }, autoSliderTimeOut);

                    // Check if right image clicked
                    if(currentFigures[j].classList.contains("right")){
                        // activate next slide
                        const currentSlide = event.target.closest('.js-slide');
                        activateSlide(currentSlide, 'right');
                    }

                    // Check if left image clicked
                    if(currentFigures[j].classList.contains("left")){
                        // activate previous slide
                        const currentSlide = event.target.closest('.js-slide');
                        activateSlide(currentSlide, 'left');
                    }
                });

                // Add mouse event listener to show custom cursor
                const toggleCursorVisibility = () => {
                    document.body.classList.toggle("show-cursor");
                }

                currentFigures[j].addEventListener("mouseenter", toggleCursorVisibility);
                currentFigures[j].addEventListener("mouseleave", toggleCursorVisibility);
            }
        }
    }

    // Start slider autoplay
    autoSlider = setInterval(startSlideTimer, sliderTiming);

    // Start autoplaying
    function startSlideTimer() {
        // only start slideshow if user does not prefer reduced motion
        if(!reducedMotion.matches) {
            // Hide some info while playing
            document.body.classList.remove('is-paused');

            const currentSlide = document.querySelector('.js-slide.is-active');
            activateSlide(currentSlide);
        }
    }

    // Stop autoplaying
    function stopAutoSlider() {
        clearInterval(autoSlider);
        // Show client-urls
        document.body.classList.add('is-paused');
    }

    // Activate specific slides
    function activateSlide(currentSlide, direction, nextSlide = null) {

        // Remove active class from current slide
        currentSlide.classList.remove('is-active');

        // Look for next slide
        let next;

        if(nextSlide) {
            // Use predefined target
            next = nextSlide;

        } else {
            // Look for next element
            if(!direction) {
                // Look for next element
                next = currentSlide.nextSibling;

                // Skip whitespace and empty nodes
                while (next && next.nodeType !== 1) {
                    next = next.nextSibling;
                }
            } else {
                next = direction === 'right' ? currentSlide.nextSibling : currentSlide.previousSibling;

                // Skip whitespace and empty nodes
                while (next && next.nodeType !== 1) {
                    next = direction === 'right' ? next.nextSibling : next.previousSibling;
                }

            }

            // Use first or last element if no more siblingss
            if(!next) {
                if(direction === "left") {
                    const allNodes = document.querySelectorAll('.js-slide');
                    next = allNodes[allNodes.length-1];
                } else {
                    next = document.querySelector('.js-slide');
                }
            }
        }

        // Add active class to choosen next slide
        next.classList.add('is-active');

        // Check if image should be swapped for multi image slides
        const left = currentSlide.querySelector('.slider__slide-side--left');
        const right = currentSlide.querySelector('.slider__slide-side--right');
        updateMultiImage(left);
        updateMultiImage(right);
    }

    /**
     * Updates the multi image in the slider side
     * @param {HTMLElement} sliderSide The left or right slider side
     */
    function updateMultiImage(sliderSide) {
        if (sliderSide && typeof sliderSide.dataset.index !== 'undefined') {
            let index = Number(sliderSide.dataset.index);
            let maxIndex = Number(sliderSide.dataset.maxIndex);
            sliderSide.dataset.index = String(index >= maxIndex ? 0 : index + 1);
        }
    }

    // ***

    // *** CLIENTS NAVIGATION
    // Look for client links in header
    const clientLink = document.querySelectorAll('.js-client-link');

    // Add click listener for every client
    for (let i = 0; i < clientLink.length; ++i) {
        clientLink[i].addEventListener("click", (e) => {
            // Prevent default behaviour
            e.preventDefault();

            // Stop automated timer
            stopAutoSlider();

            // Reset restarting of auto slider
            clearTimeout(timeOutInterval);

            // Restart slider after some timeout
            timeOutInterval = setTimeout((e) => {
                autoSlider = setInterval(startSlideTimer, sliderTiming);
            }, autoSliderTimeOut);

            // Get slide id via href
            const slideId = clientLink[i].getAttribute('href');

            // Show client slide
            const currentSlide = document.querySelector('.js-slide.is-active');
            activateSlide(currentSlide, null, document.querySelector(slideId));
        });
    }
}
// ***




// *** Copy Stuff to CLIPBOARD
// Trigger element
const clipboardTriggers = document.querySelectorAll('.js-copy-to-clipboard');

// Clipboard copy function
function copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement('textarea');

    // Set value (string to be copied)
    el.value = str;

    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);

    // Select text inside element
    el.select();

    // Copy text to clipboard
    document.execCommand('copy');

    // Remove temporary element
    document.body.removeChild(el);
}

// Add event listeners to trigger clipboard copy on click
for (let i = 0; i < clipboardTriggers.length; i++) {
    clipboardTriggers[i].addEventListener('click', event => {
        // Look for text
        const clipboardText = clipboardTriggers[i].dataset.clipboard;
        copyStringToClipboard(clipboardText);
    });
}




// *** NOTIFICATIONS
const notifications = document.querySelectorAll('.js-notification-close');

for (let i = 0; i < notifications.length; i++) {
    notifications[i].addEventListener('click', event => {
        event.preventDefault();
        event.target.closest(".js-notification").remove();
    })
}