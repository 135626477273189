
// * SHOW AND HIDE FORMS
const formTriggers = document.querySelectorAll('.js-show-form');
for (let i = 0; i < formTriggers.length; i++) {
    formTriggers[i].addEventListener('click', event => {

        // Hide slider
        const slider = document.querySelector('.js-slider');
        slider.setAttribute('aria-hidden', true);

        // Look for form id and toggle hidden status
        const formId = formTriggers[i].dataset.formId;
        const myForm = document.getElementById(formId);
        if(myForm) {
            myForm.setAttribute('aria-hidden', false);
            document.body.classList.add('has-form');
        }

        const otherTriggers = Array.from(formTriggers).filter(trigger=>trigger.dataset.formId !== formTriggers[i].dataset.formId);

        for (let i = 0; i < otherTriggers.length; i++) {
            const langForm = document.getElementById(otherTriggers[i].dataset.formId);
            if(langForm) {
                langForm.setAttribute('aria-hidden', true);
            }
        }

        // hide invalid message when language changes
        const invalidMessages = document.querySelectorAll('.js-invalid-message');
        for (let i = 0; i < invalidMessages.length; i++) {
            invalidMessages[i].style.display = "none";
        }

        // check for required checkboxes
        requireCheckboxes();
    });
}

// * FORM SUBMISSION
const enForm = document.querySelector('.js-job-form-en');
const deForm = document.querySelector('.js-job-form-de');
enForm?.addEventListener('submit', submitHandler);
deForm?.addEventListener('submit', submitHandler);

function submitHandler(e) {
    e.preventDefault();

    let formData;
    if(e.target.id == 'form-en') {
        formData = new FormData(enForm);
    } else formData = new FormData(deForm);

    const options = {
        method: 'POST',
        body: formData
    }

    // Make API call to /join endpoint
    async function postApplication() {
        const fetchedData = await (
            await fetch(`http://meitner.h-m.io:3000/join`, options)
        ).json();

        if(fetchedData.error) {
            if(e.target.id == 'form-de') {
                formResponse(fetchedData.name, 'de', true);
            } else formResponse(fetchedData.name, 'en', true);
        } else {
            console.log(`${fetchedData.name}’s POST request to Notion database was successful.`);
            if(e.target.id == 'form-de') {
                formResponse(fetchedData.name, 'de', false);
            } else formResponse(fetchedData.name, 'en', false);
        }
    }

    postApplication();
}

// Wait for response and show message
function formResponse(name, lang, error) {
    const responseNode = document.querySelector('.js-form-response');
    const formNodes = document.querySelectorAll('.js-job-form-en, .js-job-form-de');
    for (let i = 0; i < formNodes.length; i++) {
        formNodes[i].parentNode.style.display = 'none';
    }

    const responseParagraph = responseNode.querySelector('p');
    let responseText = '';

    if(error) {
        if(lang == 'de') {
            responseText = responseNode.dataset.textErrorDe;
        } else responseText = responseNode.dataset.textErrorEn;
    } else {
        if(lang == 'de') {
            responseText = responseNode.dataset.textSuccessDe;
        } else responseText = responseNode.dataset.textSuccessEn;
    }

    responseParagraph.innerText = responseText.replace("{name}", name);
    responseNode.style.display = 'block';
}


// * CUSTOM FILE INPUT
const customFileInput = document.querySelector('.js-file-input');
if(customFileInput) {
    // Display file name when uploaded
    customFileInput.onchange = e => {
        const fileName = document.querySelector('.form__item-name')
        fileName.style.display = "block";
        fileName.innerText = e.target.files[0].name;
    };
}


// * REQUIRED CHECKBOXES
function requireCheckboxes() {
    const allCheckboxes = document.querySelectorAll('form[aria-hidden="false"] .js-checkbox-required');

    // check required for all checkboxes on load
    for (let i = 0; i < allCheckboxes.length; i++) {
        requireCheckbox(allCheckboxes[i].name);
    }

    // check required for all checkboxes on change
    for (let i = 0; i < allCheckboxes.length; i++) {
        allCheckboxes[i].addEventListener('click', e => {
            requireCheckbox(e.target.name);
        });
    }
}

function requireCheckbox(elName) {
    const fieldsetElement = document.getElementsByName(elName);

    if(fieldsetElement.length > 0){
        // Look for all required checkboxes
        let checkboxes = [];
        for (let i = 0; i < fieldsetElement.length; i++) {
            const found = fieldsetElement[i].querySelectorAll('.js-checkbox-required');
            for (let i = 0; i < found.length; i++) {
                checkboxes.push(found[i]);
            }
        }

        let oneChecked = false;

        // Check if at least one is checked
        for (i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked ? oneChecked = true : null;
        }

        // Require only if none is checked
        if (oneChecked) {
            for (i = 0; i < checkboxes.length; i++) {
                checkboxes[i].required = false;
            }
        } else {
            for (i = 0; i < checkboxes.length; i++) {
                checkboxes[i].required = true;
            }
        }
    }
}

// * VALIDATION CHECK
const submitButton = document.querySelectorAll('.js-submit-button');
if(submitButton){
    for (i = 0; i < submitButton.length; i++) {
        submitButton[i].addEventListener('click', ()=>{
            // reset invalid for all inputs
            const labels = document.querySelectorAll('.js-input-label');
            for (i = 0; i < labels.length; i++){
                labels[i].classList.remove('invalid__input');
            }

            // check and mark invalid inputs
            const allInputs = document.querySelectorAll("[required]");
            for (i = 0; i < allInputs.length; i++) {
                allInputs[i].oninvalid = (e)=>{
                    e.preventDefault();
                    e.target.focus();
                    const parentNode = e.target.closest('.js-form-item');
                    const label = parentNode.querySelector('.js-input-label');
                    label.classList.add('invalid__input')
                    const invalidMessages = document.querySelectorAll('.js-invalid-message');
                    for (let i = 0; i < invalidMessages.length; i++) {
                        invalidMessages[i].style.display = "block";
                    }
                }
            };
        })
    }
};